<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div v-if="isshow" class="bottomCss">
      <div
        style="
          display: flex;
          justify-content: center;
          padding-top: 54px;
          padding-bottom: 36px;
        "
      >
        <div v-for="(item, index) in this.directoryData" :key="index">
          <div
            class="directory"
            :class="{ directory2: index == current }"
            @click="change(index)"
          >
            <img
              style="margin-top: 26px; margin-bottom: 17px"
              :src="item.url"
              alt=""
            />
            <div class="directoryName">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div>
        <!-- 左边导航 -->
        <div
          v-show="showflag"
          id="daohang"
          style="
            position: fixed;
            z-index: 9999;
            margin-left: 60px;
            cursor: pointer;
          "
        >
          <div
            class="anniu"
            :class="{ anniu2: 'chuneng' == current }"
            @click="gomove('chuneng')"
          >
            {{ this.daohangData[0].oneName }}
            <div class="anniuimg">
              <img style="margin-left: 12px" src="./img/按钮1.png" alt="" />
            </div>
          </div>
          <div v-for="(item, index) in daohangData" :key="index">
            <div
              style="
                width: 2px;
                height: 65px;
                background: #0940fd;
                border-radius: 0px 0px 0px 0px;
                opacity: 0.1;
                margin: 16px 204px;
              "
            ></div>
            <div
              class="anniu"
              :class="{ anniu2: item.dingwei == current }"
              @click="gomove(item.dingwei)"
            >
              {{ item.title }}
              <div class="anniuimg">
                <img style="margin-left: 12px" src="./img/按钮1.png" alt="" />
              </div>
            </div>
          </div>

          <!-- <div
            style="
              width: 2px;
              height: 65px;
              background: #0940fd;
              border-radius: 0px 0px 0px 0px;
              opacity: 0.1;
              margin: 16px 204px;
            "
          ></div>
          <div class="anniu" @click="gomove('tongxin')">
            通信储能系列
            <div class="anniuimg">
              <img style="margin-left: 12px" src="./img/按钮1.png" alt="" />
            </div>
          </div>
          <div
            style="
              width: 2px;
              height: 65px;
              background: #0940fd;
              border-radius: 0px 0px 0px 0px;
              opacity: 0.1;
              margin: 16px 204px;
            "
          ></div>
          <div class="anniu" @click="gomove('dianli')">
            集中式储能系列
            <div class="anniuimg">
              <img style="margin-left: 12px" src="./img/按钮1.png" alt="" />
            </div>
          </div> -->
        </div>

        <!-- 第一组产品 -->
        <div id="chuneng" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >户用储能系列</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList1"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第二组产品 -->
        <div id="bianxie" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >便携式储能系列</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList2"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第三组产品 -->
        <div id="tongxin" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >通信储能系列</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList3"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第四组产品 -->
        <div id="dianli" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >集中式储能系列产品</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList4"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第五组产品 -->
        <div id="zu5" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >集中式储能系列产品</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList5"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第六组产品 -->
        <div id="zu6" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >集中式储能系列产品</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList6"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 第七组产品 -->
        <div id="zu7" class="chanpingCss">
          <!-- <div style="margin-top: 42px; margin-bottom: 32px">
            <img src="./img/左.png" alt="" />
            <span
              style="
                font-size: 30px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                margin: 0px 86px;
              "
              >集中式储能系列产品</span
            >

            <img src="./img/右.png" alt="" />
          </div> -->
          <div
            class="chanping"
            v-for="(item, index) in this.dataList7"
            :key="index"
            @click="go(item)"
          >
            <div class="leftDiv">
              <div class="nameCss">{{ item.content.dataList[0].name }}</div>
              <div style="display: flex">
                <div
                  class="biaoqian"
                  v-for="(itembq, index) in item.content.dataList[0].biaioqian"
                  :key="index"
                >
                  {{ itembq.bq }}
                </div>
              </div>
              <div class="jieshao">{{ item.content.dataList[0].jieshao }}</div>
            </div>
            <div class="imgCss">
              <img
                class="imgCss"
                :src="item.content.dataList[0].imgdata[0].url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 内容页 -->

    <div
      v-if="!isshow"
      style="
        width: 100%;
        height: 100%;
        background: #f2f5f8;
        display: flex;
        justify-content: center;
      "
    >
      <div
        style="
          width: 69%;
          background: #ffffff;
          margin-top: 4%;
          margin-bottom: 4%;
        "
      >
        <!-- 标题图片 -->
        <div
          style="
            font-size: 38px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 54px;
          "
        >
          <div>{{ this.editorText.content.dataList[0].name }}</div>
          <div style="display: flex; flex-direction: column">
            <img
              style="
                width: 500px;
                height: 500px;
                margin-bottom: 16px;
                margin-top: 42px;
              "
              :src="this.datu"
              alt=""
            />
            <div style="display: flex; justify-content: space-evenly">
              <div
                v-for="(item, index) in this.editorText.content.dataList[0]
                  .imgdata"
                :key="index"
              >
                <img
                  @click="qiehuan(index)"
                  class="xioatu"
                  :src="item.url"
                  alt=""
                />
              </div>
              <!-- <img class="xioatu" :src="this.imgdata[2].url" alt="" />
              <img class="xioatu" :src="this.imgdata[3].url" alt="" /> -->
            </div>
          </div>
        </div>
        <!-- 产品简介 -->
        <div class="jianjie">
          <div
            style="
              margin-top: 42px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
          Product introduction
          </div>
          <div style="display: flex; margin-top: 24px; margin-bottom: 24px">
            <div
              v-for="(item, index) in this.editorText.content.dataList[0]
                .biaioqian"
              :key="index"
              style="
                margin-right: 12px;
                height: 36px;
                background: rgba(9, 64, 253, 0.1);
                font-size: 16px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                border-radius: 6px 6px 6px 6px;
                color: #0940fd;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
              "
            >
              {{ item.bq }}
            </div>
          </div>
          <div
            style="
              font-size: 20px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              line-height: 38px;
              margin-bottom: 36px;
            "
          >
            {{ this.editorText.content.dataList[0].jieshao }}
          </div>
        </div>
        <!-- 产品优点 -->
        <div class="youdian">
          <div
            style="
              margin-top: 36px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
          Product advantage
          </div>
          <div class="ydlb">
            <div
              class="ydnr"
              v-for="(item, index) in this.editorText.content.dataList[0]
                .youdianData"
              :key="index"
            >
              {{ item.yd }}
            </div>
          </div>
        </div>
        <!-- 关键参数 -->
        <div class="canshu">
          <div
            style="
              margin-top: 36px;
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
          >
          Parameters
          </div>
          <div class="csimg">
            <img
              style="width: 100%"
              :src="this.editorText.content.dataList[0].canshuData[0].url"
              alt=""
            />
            <!-- <img
              style="width: 100%"
              :src="this.editorText.content.dataList[0].canshuData[1].url"
              alt=""
            /> -->
          </div>
        </div>
      </div>

      <div style="position: fixed; right: 10px; bottom: 144px">
      <div v-if="!isshow" @click="fanhui" class="fhsj">
        <div class="fanhui">
          <img src="@/assets/返回上级蓝.png" alt="" />
        </div>
        <div>Return</div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { getContents } from "@/api/index.js";
export default {
  data() {
    return {
      datu: "",
      editorText: "",
      isshow: true,
      showflag: true,
      daohangData: [
        {
          oneName: "户用储能系列",
          title: "便携式储能系列",
          dingwei: "bianxie",
        },
        { oneName: "户用储能系列", title: "通信储能系列", dingwei: "tongxin" },
        { oneName: "户用储能系列", title: "工商业储能系列", dingwei: "dianli" },
        { oneName: "户用储能系列", title: "集中式储能系列", dingwei: "zu5" },
      ],
      current: 0, //选中项
      directoryData: [
        { title: "Stored Energy", url: require("./img/储能.png") },
        { title: "Charging Pile", url: require("./img/新能源.png") },
        // { title: "Intelligent series", url: require("./img/智能化.png") },
      ],
      dataList1: [
        {
          name: "单相混合式逆变器",
          biaoqian: [
            { bq: "IP65防尘防水" },
            { bq: "MPPT电压范围宽" },
            { bq: "多种运行方式" },
            { bq: "保护功能齐全" },
          ],
          jieshao:
            "该系列产品旨在提高业主的能源独立性，基于使用时间和峰谷电价进行能源管理，能显著减少从公共电网购买的电量，并优化能源使用率。",
          url: require("@/views/product/img/单相.png"),
          router: "/ProductDetails",
        },
        {
          name: "三相混合式逆变器",
          biaoqian: [
            { bq: "IP65防尘防水" },
            { bq: "自动管理电池充放电" },
            { bq: "全方位安全保护" },
            { bq: "电池反向连接保护" },
          ],
          jieshao:
            "该系列产品旨在提高业主的能源独立性，基于使用时间和峰谷电价进行能源管理，能显著减少从公共电网购买的电量，并优化能源使用率。",
          url: require("@/views/product/img/三相混合逆变器.png"),
          router: "/sanxiangnibianqi",
        },
        {
          name: "低压堆叠电池组",
          biaoqian: [
            { bq: "磷酸铁锂电池" },
            { bq: "精巧外观设计" },
            { bq: "容量可扩展" },
            { bq: "低噪音设计" },
            { bq: "IP65防尘防水" },
          ],
          jieshao:
            "低压堆叠电池组除“高能效”特点外，电池的安全性能大大提升，覆盖5-20kWh的户用储能需求，与光伏、电网及家庭有机连接，形成柔性协同，让用户获得全天候的绿色低碳的用电体验。",
          url: require("@/views/product/img/低压电池1.png"),
          router: "/diyadianchizu",
        },
        {
          name: "高压堆叠电池组",
          biaoqian: [
            { bq: "磷酸铁锂电池" },
            { bq: "精巧外观设计" },
            { bq: "模块化设计" },
            { bq: "低噪音设计" },
            { bq: "IP65防尘防水" },
          ],
          jieshao:
            "新一代高压堆叠电池组采用堆叠设计,安装方便,电池组支持并联、扩客,时刻检测电池状态,均衡放电,具有过充、过放、短路、温度、过流以及过压等全方位保护。",
          url: require("@/views/product/img/高压电池1.png"),
          router: "/gaoyadianchizu",
        },
      ],
      dataList2: [
        {
          name: "600W户外电源",
          biaoqian: [
            { bq: "正弦波电流" },
            { bq: "多接口设计" },
            { bq: "防震抗摔" },
            { bq: "充电方式多样化" },
            { bq: "智能用电防护系统" },
          ],
          jieshao:
            "户外600W便捷电源拥有448Wh的容量，是自驾旅行、家庭应急的必备电源，600W高功率正弦波AC输出，循环寿命可达3000次。内置车充口12V输出，方便快捷。",
          url: require("@/views/product/img/600w户外.png"),
          router: "/600Whuwai",
        },
        {
          name: "1200W户外电源",
          biaoqian: [
            { bq: "UL认证电池" },
            { bq: "BMS 智能控制系统" },
            { bq: "智能温度控制系统" },
            { bq: "震动和跌落电阻材料" },
          ],
          jieshao:
            "户外电源1200W拥有1008Wh大容量，搭配1200W 大功率输出，支持市电与光伏快充，充满均只需1.8小时。",
          url: require("@/views/product/img/1200w户外.png"),
          router: "/1200Whuwai",
        },
      ],
      dataList3: [
        {
          name: "基站备电用磷酸铁锂电池",
          biaoqian: [
            { bq: "超高精度" },
            { bq: "远程监控及管理" },
            { bq: "BMS高效的散热结构" },
            { bq: "全方位保护" },
          ],
          jieshao:
            "该产品适用于15系列理电池电信基站电源，可为理电池组提供过充、过放、过流、过温、欠温、充电限流、短路保护功能。并可提供充电过程中的电压均衡功能以及充放电电压、电流信息的存储。可通过RS485进行并行通信，可通过RS485与上位机通信，可通过PC机软件进行参数配置和数据监控，也可通过RS485与动态回路系统通信。",
          url: require("@/views/product/img/通信储能.png"),
          router: "/jizhanbeiyong",
        },
      ],
      dataList4: [
        {
          name: "工商业储能——风冷",
          biaoqian: [
            { bq: "结构简单" },
            { bq: "安全可靠" },
            { bq: "经济高效" },
          ],
          jieshao:
            "该产品采用储能一体柜作为储能系统的载体，采用模块化设计，将一套磷酸铁理电池柜、电池管理系统BMS、储能变流器PCS、本地控制系统EMS、空调、消防等设备集成在一体柜内。可灵活布置在多种工商业应用场景，帮助企业降低用电成本。",
          url: require("@/views/product/img/工商业储能-风冷.png"),
          router: "/fenglengchuneng",
        },
        {
          name: "工商业储能——液冷",
          biaoqian: [
            { bq: "能量密度高" },
            { bq: "高效液冷散热" },
            { bq: "变频控制技术" },
            { bq: "多级安全监控技术" },
          ],
          jieshao:
            "该产品基于风冷储能升级后新一代储能产品，具有高防护电池模组，高精度温控系统，通过液冷将电芯温度控制在 4度以内,较传统风冷系统大大提升整体设备使用寿命。",
          url: require("@/views/product/img/工商业储能-液冷.png"),
          router: "/yelengchuneng",
        },
      ],
      dataList5: [
        {
          name: "风冷储能系统",
          biaoqian: [
            { bq: "定制化" },
            { bq: "灵活的模块化设计" },
            { bq: "系统扩展可灵活调节" },
          ],
          jieshao:
            "该产品为电池舱一体化智慧储能产品,电池箱的设计完全满足发电侧、电网侧、用户侧的实际需求;同时风冷储能系统可以实现主动均衡和被动均衡的切换；高压箱设计有低压1000V、高压1500V系统，满足低压电池簇和高压电池簇的实际需求。",
          url: require("@/views/product/img/集装箱储能.png"),
          router: "/jizhuangxiang",
        },
        {
          name: "液冷储能系统",
          biaoqian: [
            { bq: "高能量密度" },
            { bq: "效率提升" },
            { bq: "电池寿命提升" },
          ],
          jieshao:
            "该产品基于风冷储能升级后新一代储能产品，具有高防护电池模组,高精度温控系统,通过液冷将电芯温度控制在4度以内,较传统风冷系统大大提升整体设备使用寿命。",
          url: require("@/views/product/img/液冷储能.png"),
          router: "/yelenchuneng",
        },

        {
          name: "升压一体机",
          biaoqian: [
            { bq: "即插即用" },
            { bq: "集成高精度温湿度检测系统" },
            { bq: "高等级防护" },
          ],
          jieshao:
            "该产品是将三电平PCS、开关柜和升压变压器进行了优化集成，具有集成度高、单机功率大、内部结构紧凑、占地面积较小、应用简单、安装灵活等优点,可选择10KV/35KV电压等级配置直接接入电网。",
          url: require("@/views/product/img/升压一体机.png"),
          router: "/shengyayitiji",
        },
      ],

      dataList6: [],
      dataList7: [],
    };
  },
  methods: {
    qiehuan(index) {
      if (index == 0) {
        this.datu = this.editorText.content.dataList[0].imgdata[0].url;
      } else if (index == 1) {
        this.datu = this.editorText.content.dataList[0].imgdata[1].url;
      } else if (index == 2) {
        this.datu = this.editorText.content.dataList[0].imgdata[2].url;
      }
    },
    fanhui() {
      this.isshow = !this.isshow;
    },
    //路由跳转事件
    go(index) {
      console.log(index,'index');
      // console.log(index.content.dataList[0].name);
      // this.$router.push({ path: index.router, query: { key: index } });
      this.isshow = !this.isshow;
      this.editorText = index;
      this.datu=this.editorText.content.dataList[0].imgdata[0].url
      console.log(this.editorText);
      console.log(this.editorText.content.dataList[0].name);
    },
    async change(x) {
      this.current = x;

      this.daohangData = [
      {
          oneName: "Household",
          title: "Portable",
          dingwei: "bianxie",
        },
        { oneName: "Household", title: "Communication", dingwei: "tongxin" },
        { oneName: "Household", title: "Industry and Commerce", dingwei: "dianli" },
        { oneName: "Household", title: "Centralized", dingwei: "zu5" },
      ];

      if (x == 0) {
        let params = {
          menuId: "2068",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
          res[index].content.dataList[0].biaioqian = res[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res[index].content.dataList[0].youdianData = res[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList1 = res;

        let params2 = {
          menuId: "2069",
          id: "",
        };
        const res2 = await getContents(params2);
        for (let index = 0; index < res2.length; index++) {
          res2[index].content = JSON.parse(res2[index].content);
          res2[index].content.dataList[0].biaioqian = res2[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res2[index].content.dataList[0].youdianData = res2[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList2 = res2;

        let params3 = {
          menuId: "2070",
          id: "",
        };
        const res3 = await getContents(params3);
        for (let index = 0; index < res3.length; index++) {
          res3[index].content = JSON.parse(res3[index].content);
          res3[index].content.dataList[0].biaioqian = res3[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res3[index].content.dataList[0].youdianData = res3[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList3 = res3;

        let params4 = {
          menuId: "2071",
          id: "",
        };
        const res4 = await getContents(params4);
        for (let index = 0; index < res4.length; index++) {
          res4[index].content = JSON.parse(res4[index].content);
          res4[index].content.dataList[0].biaioqian = res4[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res4[index].content.dataList[0].youdianData = res4[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList4 = res4;

        let params5 = {
          menuId: "2072",
          id: "",
        };
        const res5 = await getContents(params5);
        for (let index = 0; index < res5.length; index++) {
          res5[index].content = JSON.parse(res5[index].content);
          res5[index].content.dataList[0].biaioqian = res5[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res5[index].content.dataList[0].youdianData = res5[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList5 = res5;

        this.dataList6 = [];
        this.dataList7 = [];
      } else if (x == 1) {
        this.daohangData = [
          { oneName: "Ac charging pile", title: "Dc charging pile", dingwei: "bianxie" },
        ];
        let params = {
          menuId: "2074",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
          res[index].content.dataList[0].biaioqian = res[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res[index].content.dataList[0].youdianData = res[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList1 = res;

        let params2 = {
          menuId: "2075",
          id: "",
        };
        const res2 = await getContents(params2);
        for (let index = 0; index < res2.length; index++) {
          res2[index].content = JSON.parse(res2[index].content);
          res2[index].content.dataList[0].biaioqian = res2[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res2[index].content.dataList[0].youdianData = res2[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList2 = res2;

        this.dataList3 = [];
        this.dataList4 = [];
        this.dataList5 = [];
        this.dataList6 = [];
        this.dataList7 = [];
      } else if (x == 2) {
        this.daohangData = [
          {
            oneName: "Entrance guard",
            title: "Smart parking",
            dingwei: "bianxie",
          },
          {
            oneName: "",
            title: "Intelligent lighting",
            dingwei: "tongxin",
          },
          { oneName: "", title: "Building control", dingwei: "dianli" },
          { oneName: "", title: "Energy management system", dingwei: "zu5" },
          { oneName: "", title: "Integrated management platform", dingwei: "zu6" },
          { oneName: "", title: "Multifunctional rod series products", dingwei: "zu7" },
        ];
        let params = {
          menuId: "2077",
          id: "",
        };
        const res = await getContents(params);
        for (let index = 0; index < res.length; index++) {
          res[index].content = JSON.parse(res[index].content);
          res[index].content.dataList[0].biaioqian = res[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res[index].content.dataList[0].youdianData = res[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList1 = res;

        let params2 = {
          menuId: "2078",
          id: "",
        };
        const res2 = await getContents(params2);
        for (let index = 0; index < res2.length; index++) {
          res2[index].content = JSON.parse(res2[index].content);
          res2[index].content.dataList[0].biaioqian = res2[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res2[index].content.dataList[0].youdianData = res2[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList2 = res2;

        let params3 = {
          menuId: "2079",
          id: "",
        };
        const res3 = await getContents(params3);
        for (let index = 0; index < res3.length; index++) {
          res3[index].content = JSON.parse(res3[index].content);
          res3[index].content.dataList[0].biaioqian = res3[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res3[index].content.dataList[0].youdianData = res3[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList3 = res3;

        let params4 = {
          menuId: "2080",
          id: "",
        };
        const res4 = await getContents(params4);
        for (let index = 0; index < res4.length; index++) {
          res4[index].content = JSON.parse(res4[index].content);
          res4[index].content.dataList[0].biaioqian = res4[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res4[index].content.dataList[0].youdianData = res4[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList4 = res4;

        let params5 = {
          menuId: "2081",
          id: "",
        };
        const res5 = await getContents(params5);
        for (let index = 0; index < res5.length; index++) {
          res5[index].content = JSON.parse(res5[index].content);
          res5[index].content.dataList[0].biaioqian = res5[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res5[index].content.dataList[0].youdianData = res5[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList5 = res5;

        let params7 = {
          menuId: "2082",
          id: "",
        };
        const res7 = await getContents(params7);
        for (let index = 0; index < res7.length; index++) {
          res7[index].content = JSON.parse(res7[index].content);
          res7[index].content.dataList[0].biaioqian = res7[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res7[index].content.dataList[0].youdianData = res7[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList7 = res7;

        let params6 = {
          menuId: "2083",
          id: "",
        };
        const res6 = await getContents(params6);
        for (let index = 0; index < res6.length; index++) {
          res6[index].content = JSON.parse(res6[index].content);
          res6[index].content.dataList[0].biaioqian = res6[
            index
          ].content.dataList[0].biaioqian.map((item) => {
            return { bq: item };
          });
          res6[index].content.dataList[0].youdianData = res6[
            index
          ].content.dataList[0].youdianData.map((item) => {
            return { yd: item };
          });
        }
        this.dataList6 = res6;
      }
    },
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      console.log(this.scrollTop);
      if (this.current == 0) {
        if (this.scrollTop > 590 && this.scrollTop < 4000) {
          let body = document.getElementById("daohang");
          body.style.top = "10%";
          body.style.display = "block";
        } else if (this.scrollTop < 600) {
          let body = document.getElementById("daohang");
          body.style.top = "";
          body.style.display = "block";
          // body.style.transition = "all 5s";
        } else if (this.scrollTop > 4000) {
          // this.showflag=false
          let body = document.getElementById("daohang");
          body.style.display = "none";
        }
      } else if (this.current == 1) {
        if (this.scrollTop > 590 && this.scrollTop < 2700) {
          let body = document.getElementById("daohang");
          body.style.top = "10%";
          body.style.display = "block";
        } else if (this.scrollTop < 600) {
          let body = document.getElementById("daohang");
          body.style.top = "";
          body.style.display = "block";
          // body.style.transition = "all 5s";
        } else if (this.scrollTop > 2700) {
          // this.showflag=false
          let body = document.getElementById("daohang");
          body.style.display = "none";
        }
      } else if (this.current == 2) {
        if (this.scrollTop > 590 && this.scrollTop < 5200) {
          let body = document.getElementById("daohang");
          body.style.top = "10%";
          body.style.display = "block";
        } else if (this.scrollTop < 600) {
          let body = document.getElementById("daohang");
          body.style.top = "";
          body.style.display = "block";
          // body.style.transition = "all 5s";
        } else if (this.scrollTop > 5200) {
          // this.showflag=false
          let body = document.getElementById("daohang");
          body.style.display = "none";
        }
      }
    },
    gomove(val) {
      this.current = val;
      // document.getElementsByClassName('chanpingCss').scrollIntoView()
      document.getElementById(val).scrollIntoView();
    },
  },
  mounted() {
    this.change(0);
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>



<style scoped>

::v-deep.el-backtop {
  width: 82px;
  height: 82px;
}
.fhsj {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  /* text-align: center; */
  width: 82px;
  height: 82px;
  background: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.fhsj:hover {
  cursor: pointer;
  background: #0940fd;
  color: rgba(255, 255, 255, 0.6);
  .fanhui img {
    content: url("../../../assets/返回上级.png");
  }
}
.csimg {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.canshu {
  margin: 0px 64px 64px 64px;
}
.ydlb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
}
.ydnr {
  width: 50%;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.ydnr::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin-right: 16px;
}
.youdian {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.jianjie {
  margin: 0px 64px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.xioatu {
  width: 110px;
  height: 110px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px 12px 12px 12px;
}
.xioatu:hover {
  width: 110px;
  height: 110px;
  border: 2px solid rgba(9, 64, 253, 1);
  border-radius: 12px 12px 12px 12px;
}

.bottomCss {
  width: 100%;
  height: 100%;
  padding-bottom: 88px;
  background: #f2f5f8;
}
.directory {
  width: 300px;
  height: 120px;
  background: #ffffff;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.directory:hover {
  width: 300px;
  height: 120px;
  background: #0940fd;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}
.directory2 {
  width: 300px;
  height: 120px;
  background: #0940fd;
  text-align: center;
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}
.chanpingCss {
  display: flex;
  /* text-align: center; */
  flex-direction: column;
  align-items: center;
}
.chanping {
  display: flex;
  width: 1314px;
  height: 363px;
  background: #ffffff;
  margin-bottom: 16px;
  cursor: pointer;
}
.chanping:hover {
  display: flex;
  width: 1314px;
  height: 363px;
  background: #ffffff;
  margin-bottom: 16px;
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.2);
}
.chanping:hover::before {
  content: "";
  border-left: 8px solid #0940fd;
  height: 226px;
  margin-top: 54px;
}
.leftDiv {
  margin-left: 64px;
}
.nameCss {
  font-size: 36px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  margin-top: 54px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 30px;
}
.biaoqian {
  margin-right: 12px;
  margin-top: 24px;
  height: 36px;
  background: rgba(9, 64, 253, 0.1);
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px 6px 6px 6px;
  color: #0940fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.jieshao {
  margin-top: 24px;
  width: 870px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);

  /* 最多四行 */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.imgCss {
  margin: 16px 24px;
  width: 310px;
  height: 310px;
}
.anniu {
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 46%;
}
.anniu:hover {
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 46%;

  /* .anniuimg{
        
        box-shadow: 0px 3px 6px 1px rgba(9,64,253,0.3);
        
    } */
  .anniuimg img {
    border-radius: 50%;
    box-shadow: 0px 3px 6px 1px rgba(9, 64, 253, 0.3);
    content: url("./img/按钮2.png");
  }
}
.anniu2 {
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 46%;
  .anniuimg img {
    border-radius: 50%;
    box-shadow: 0px 3px 6px 1px rgba(9, 64, 253, 0.3);
    content: url("./img/按钮2.png");
  }
}
</style>